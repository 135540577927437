@import "Themes/constants.scss";
body {
	--color-neutral-1: #{$color-Sunrise-Purple};
	--color-neutral-2: #{$color-Sunrise-Metaviolet};
	--color-neutral-2-hover: #{$color-Sunrise-Metaviolet-Hover};
	--color-neutral-3: #{$color-Sunrise-Lavender};
	--color-neutral-4: #{$color-Sunset-MetaRed};
	--color-neutral-5: #{$color-Sunset-MetaYellow};
	--color-neutral-6: #{$color-Sunset-DeepPeach};
	--color-neutral-7: #{$color-InfiniteSky-OrionBlue};
	--color-neutral-8: #{$color-InfiniteSky-LapsisBlue};
	--color-neutral-9: #{$color-InfiniteSky-HorizonBlue};
	--color-neutral-10: #{$color-disabled};
	--color-neutral-11: #{$color-Grayscale-BlackKhol};
	--color-neutral-12: #{$color-neutral-12};

	--color-transparent-1: #{$color-transparent-1};
	--color-transparent-2: #{$color-transparent-2};
	--color-transparent-3: #{$color-transparent-3};
	--color-transparent-4: #{$color-transparent-4};

	--color-destructive-1: #{$color-destructive-1};
	--color-success-1: #{$color-success-1};

	--color-shadow-light: #{$color-shadow-light};
	--color-shadow-small: #{$color-shadow-small};
	--color-shadow-medium: #{$color-shadow-medium};

	--color-gradient-1: #{$color-gradient-1};

	--color-generic-white: #{$color-generic-white};
}

[theme-mode] {
	--color-primary-1: #{$color-generic-white};
	--color-primary-2: #{$color-Grayscale-SianaiBeige};
	--color-primary-3: #{$color-Grayscale-GalalaWhite};
	--color-primary-4: #{$color-Grayscale-GreyAccessibleOnDark};
	--color-primary-5: #{$color-Grayscale-GreyAccessibleOnWhite};
	--color-primary-6: #{$color-Grayscale-SyenitteGrey};
	--color-primary-7: #{$color-Grayscale-BlackBasalt};
	--color-primary-8: #{$color-Grayscale-BlackKhol};
}

[theme-mode="dark"] {
	--color-primary-1: #{$color-Grayscale-BlackKhol};
	--color-primary-2: #{$color-Grayscale-BlackBasalt};
	--color-primary-3: #{$color-Grayscale-SyenitteGrey};
	--color-primary-4: #{$color-Grayscale-GreyAccessibleOnWhite};
	--color-primary-5: #{$color-Grayscale-GreyAccessibleOnDark};
	--color-primary-6: #{$color-Grayscale-GalalaWhite};
	--color-primary-7: #{$color-Grayscale-SianaiBeige};
	--color-primary-8: #{$color-generic-white};
}
