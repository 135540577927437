* {
    box-sizing: border-box;
  }
  
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

#menu{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
h1{
    font-family: 'Libre Barcode 39 Text', cursive;
    font-size: 4em;
}
button{
    background-color: rgba(147, 52, 52, 0);
    color: rgb(221, 221, 221);
    font-size: 18px;
    border: none;
    cursor: pointer;
    margin-top: 10%;
    font-weight: 700;
}

#view_button{
  padding: 0;
  margin: 0;
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
