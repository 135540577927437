@import "Themes/constants.scss";

:root {
	--content-max-width: 2160px;
	--template-margin: auto;
	--content-padding: 32px;

	--font-primary: "Inter", sans-serif;
	--font-secondary: "Source Sans Pro", sans-serif;
}

body {
	
	--screen-xl: $screen-xl;
	--screen-l: $screen-l;
	--screen-m: $screen-m;
	--screen-s: $screen-s;
	--screen-xs: $screen-xs;
	
	// Get screen size in real-time
	@media (max-width: $screen-xs) {
		--screen-size: screen-xs;
	}
	@media (min-width: $screen-xs) and (max-width: $screen-s)  {
		--screen-size: screen-s;
	}
	@media (min-width: $screen-s) and (max-width: $screen-m) {
		--screen-size: screen-m;
	}
	@media (min-width: $screen-m) and (max-width: $screen-l) {
		--screen-size: screen-l;
	}
	@media (min-width: $screen-l) and (max-width: $screen-xl) {
		--screen-size: screen-xl;
	}
}