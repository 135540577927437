#nft_popup {
    position: absolute;
    background-color: #220002;
    margin: 25%;
    padding: 1rem;
    border-radius: 20px;
    border: 1px solid black;
    width: 50%;
  }
  
  #close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }

  .nft_img{
    height: 140px;
    width: 140px;
    padding: 20px;
    cursor: pointer;
  }

